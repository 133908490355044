import React, { useState } from "react";
import { Button, Input, Space, Typography } from "antd";

import PaymentList from "./PopupModal";

const App = () => {
  const [loanAmount, setLoanAmount] = useState(0);
  const [interestRate, setInterestRate] = useState(0);
  const [loanPeriod, setLoanPeriod] = useState(0);

  const handleReset = () => {
    setLoanAmount(0);
    setInterestRate(0);
    setLoanPeriod(0);
  };

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "90vh",
      }}
    >
      <div
        style={{
          border: "3px solid #cfcfcf",
          // borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          minWidth: "30vw",
          gap: "2vh",
          padding: "20px",
        }}
      >
        <Typography.Title level={2}>Loan Calculator</Typography.Title>
        <Space
          direction="vertical"
          size="middle"
          style={{
            width: "100%",
          }}
        >
          <span>Capital Amount</span>
          <Input
            placeholder="Capital Amount"
            value={loanAmount}
            onChange={(e) => setLoanAmount(e.target.value)}
            type="number"
          />
          <span>Interest Rate</span>
          <Input
            placeholder="Interest Rate"
            addonAfter="%"
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
            type="number"
          />
          <Space
            direction="vertical"
            size="middle"
            style={{
              width: "100%",
            }}
          >
            <span>Terms</span>
            <Input
              placeholder="12"
              addonAfter="Months"
              value={loanPeriod}
              onChange={(e) => setLoanPeriod(e.target.value)}
              type="number"
            />
          </Space>
          <Space
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <PaymentList
              loanAmount={loanAmount}
              interestRate={interestRate}
              loanPeriod={loanPeriod}
              footerProps={true}
            />
            <Button onClick={() => handleReset()}>Reset</Button>
          </Space>
        </Space>
      </div>
    </Space>
  );
};

export default App;
