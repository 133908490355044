import React, { useState } from "react";
import { Button, Input, Modal } from "antd";
import axios from "axios";
import ScheduleList from "./SheduleList";

const App = ({ loanAmount, interestRate, loanPeriod, footerProps }) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [NIC, setNIC] = useState("");
  const [NICError, setNICError] = useState("");
  const [schedule, setSchedule] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [cusomterNameError, setCustomerNameError] = useState("");

  const showModal = () => {
    setOpen(true);
  };
  const checkAvailableFields = () => {
    if (
      parseInt(loanAmount) !== 0 &&
      loanAmount !== "" &&
      loanAmount !== null &&
      parseInt(interestRate) !== 0 &&
      interestRate !== "" &&
      interestRate !== null &&
      parseInt(loanPeriod) !== 0 &&
      loanPeriod !== "" &&
      loanPeriod !== null
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleClick = () => {
    if (checkAvailableFields()) {
      axios
        .post("/generate", { loanAmount, interestRate, loanPeriod })
        .then((res) => {
          setSchedule(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
      showModal();
    } else {
      setNICError("NIC must be between 10 and 11 characters");
    }
  };
  const handleOk = () => {
    if (
      checkAvailableFields() &&
      NIC.length >= 10 &&
      NIC.length < 13 
      // customerName.length >= 2
    ) {
      setConfirmLoading(true);
      axios
        .post("/saveschedule", {
          NIC,
          loanAmount,
          interestRate,
          loanPeriod,
          // customerName,
        })
        .then((res) => {
          setConfirmLoading(false);
          setOpen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setNICError("NIC must be between 10 and 11 characters");
    }
  };
  const handleCancel = () => {
    setOpen(false);
    setNIC("");
  };
  return (
    <>
      <Button
        type="primary"
        disabled={!checkAvailableFields()}
        onClick={(e) => handleClick()}
      >
        Schedule
      </Button>
      <Modal
        style={{ minWidth: "800px" }}
        title="Schedule Table"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Save"
        footer={
          footerProps ? (
            <>
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>
              <Button key="submit" type="primary" onClick={handleOk}>
                Save
              </Button>
            </>
          ) : null
        }
      >
        <ScheduleList schedule={schedule} />
        <br />
        {footerProps && (
          <div>
            <span>Save Schedule</span>
            <Input
              value={NIC}
              placeholder="Enter Customer NIC"
              onChange={(e) => {
                setNIC(e.target.value);
                if (e.target.value.length >= 10 && e.target.value.length < 13) {
                  setNICError("");
                } else {
                  setNICError("NIC must be between 10 and 11 characters");
                }
              }}
              type="text"
            />
          </div>
        )}
        {NICError && <p style={{ color: "red" }}>{NICError}</p>}
        {/* {footerProps && (
          <div style={{ marginTop: "1%" }}>
            <Input
              value={customerName}
              placeholder="Enter Customer Name"
              onChange={(e) => {
                setCustomerName(e.target.value);
                if (e.target.value.length >= 2) {
                  setCustomerNameError("");
                } else {
                  setCustomerNameError("Enter a longer customer name");
                }
              }}
              type="text"
            />
            {cusomterNameError && (
              <p style={{ color: "red" }}>{cusomterNameError}</p>
            )}
          </div>
        )} */}
      </Modal>
    </>
  );
};
export default App;
