import Customers from "./components/Customers";

import React, { useState } from "react";
import { CalculatorOutlined, OrderedListOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import Calculator from "./components/Calculator";
const { Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem("Calculator", "1"),
  getItem("Customer List", "2"),
];
const Main = () => {
  const [collapsed, setCollapsed] = useState(false);

  const [selectedKeys, setSelectedKeys] = useState(["1"]);
  const handleMenuSelect = ({ key }) => {
    setSelectedKeys([key]);
  };

  const componentMap = {
    1: Calculator,
    2: Customers,
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          // defaultSelectedKeys={1}
          mode="inline"
          items={items}
          selectedKeys={selectedKeys}
          onSelect={handleMenuSelect}
          // defaultActiveFirst={["1"]}
        />
      </Sider>
      <Layout>
        <Content
          style={{
            margin: "0 16px",
          }}
        >
          {/* eslint-disable-next-line  */}
          {selectedKeys.map((key, index) => {
            if (componentMap[key]) {
              const ComponentToRender = componentMap[key];
              return <ComponentToRender key={index} />;
            }
          })}
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
        </Footer>
      </Layout>
    </Layout>
  );
};
export default Main;
